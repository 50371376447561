// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";


// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

th.sortable  {
  position: relative;

  span.svg-icon-sort {
    position: absolute;
  }
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary  !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

#area-upload .fileContainer {
  box-shadow: none;
  padding: 0;

  .chooseFileButton {

    box-shadow: none;

    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem;
  }

  .fileContainer .chooseFileButton:hover {
    background: #545972;
  }

}

@media (max-width: 990px) {
  .page {
    margin-top: 7rem;
  }
}

.mini-avatar {
  background: #8080801f;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  text-align-last: center;
}

.real-time {
  height: 0px;
  overflow: auto;
}

@media (min-width: 985px) {
  .max {
    max-width: 24% !important;
  }
}

@media (max-width: 990px) {
  .real-time {
    height: 465px;
  }
}

@media (max-width: 986px) {
  .mtt {
    margin-top: 7em;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f8891 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f8891;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.bg-warning-card {
  background-color: #FFF4DE !important;
  color: #FFA800 !important;
}

.bg-success-card {
  background-color: #C9F7F5 !important;
  color: #1BC5BD !important;
}

.bg-primary-card {
  background-color: #E1F0FF !important;
  color: #3699FF !important;
}

.bg-danger-card {
  background-color: #FFE2E5 !important;
  color: #F64E60 !important;
}

.card-t-top {
  padding-top: 10px;
}

.card-animation {
  animation: fadeIn 0.5s ease-in-out;
  animation: scale-in-ver-top .5s cubic-bezier(.25, .46, .45, .94) both
}

.card-animation-old {
  animation: fadeIn 0.5s ease-in-out;
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.tel-nm-padding button.btn.btn-outline-secondary.dropdown-toggle.country-selector {
  padding-top: 3px;
  padding-bottom: 3px;
}

.react-tel-input {
  border-color: #E4E6EF;

  .form-control.is-valid {
    border-color: #1BC5BD!important;
    padding-right: calc(1.5em + 1.3rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
  }

  .form-control.is-invalid {
    border-color: #F64E60!important;;
    padding-right: calc(1.5em + 1.3rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
  }
}


.ANIMATION{
  opacity: 1;
  transition: opacity 0.5s linear;
}

.TRANSFORM{
  .react-datepicker__header{
    background: #3699FF;
    border-radius: 0px;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color:#fff
  }
  .react-datepicker__day-name{
    color:#fff
  }

  .react-datepicker__time-container{
    border-left: 0;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 91px;
  }

  .react-datepicker{
    width: max-content;
    z-index: 9;
  }
}

body{
  
  .menu-item > .menu-link .menu-icon,
  .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill],
  .aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon.svg-icon svg g [fill], 
  .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill]
  {
    color:#3699FF !important;
    fill:#3699FF !important
  }

  .menu-item > .menu-toggle .menu-text{
    // color:#3699FF !important;
    color:#ffffff !important;
  }

}

.TABLE_NO_DATA_STYLE{
  background-color: #ededed;
}

.react-bs-table-no-data{
  text-align: center;
  // background-color: #f1f1f1;
  // font-weight: 700;
}


.monitoring .card-header{
  border:0px
}

.input-group {
  .input-group-append{
    height: 38.38px !important;
  }
}

.resolve{
  height: 31px !important;
}

#body-clone{
  display: none;

  article{
    padding:15px;      
    border-radius: 11px;    
    width:300px;
  }

  .title{
    margin-bottom:15px;
    font-size: 26px;
  }

  span, strong{
    display:block;
    text-align:center;
  }

  strong{
    margin-top:15px !important
  }

  

  #container-img{
    margin-top:15px;
    margin-bottom: 15px;
  }

  #container-img div {
    display: flex;
    justify-content: center;
  }

  .qrcode-container{
    display:flex !important;
    margin-left:12px
  }

  #container-img svg{
    // width: 37% !important;
    width: 58% !important;
  }

  #body-clone{
    display: none;
  }
}

#ALL_CONTENT{
  display: contents
}

      
@media print {
  // *{
  //   display: none;
  //   padding: 0px;
  //   margin: 0px;
  // }

  body{
    background-color: #fff;
  }

  #ALL_CONTENT
  {
    display: none; 

  }
  
  #body-clone{
    display: block !important;
  }
}

.dp-full {
  .react-datepicker-wrapper{
    width: 100%;
  }
}  