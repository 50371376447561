#FormCreate {
  height: 73vh;


  @media (max-height: 700px) {
    height: 60vh; }

  .action {
    position: absolute;
    bottom: 20px;
    right: 20px; } }


