.modal-vinc {
  .icon-layers {
      margin: 0 6px -4.5px 0;
  }

  .input-search {
      margin-bottom: 2rem;
  }

  .label-controls {
      align-self: baseline;
      color: #3699FF;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
  }

  .modal-button {
      padding: 4px 6px 4.25px 11px !important;
  }

  .modal-controls {
      display: flex;
      justify-content: space-between;
  }

  .modal-table {
      &__action {
          width: 60px;
      }

      &__icon {
          padding: 0 0 0 24px !important;
      }
  }

  .modal-title {
      color: #578ebe !important;
      text-transform: uppercase;
  }

  .MuiOutlinedInput-adornedEnd {
      padding-right: 8px !important;
  }

  .MuiOutlinedInput-input {
      padding: 0.65rem 1rem !important;
  }

  .span-arrow {
      font-size: 16px;
      font-weight: 700;
  }

  .table-container {
      margin-top: 1.25rem !important;
  }

  .row .react-bootstrap-table-pagination {
      min-height: 52px;
      justify-content: flex-end;
      margin-right: -6.5px !important;
  }

  .MuiInputAdornment-root.point.MuiInputAdornment-positionEnd {
      cursor: pointer;
  }

  .row .pagination-enable .react-bootstrap-table-pagination {
      justify-content: flex-start;
      // margin-left: 0px !important;
  }

  .modal-body {
      min-height: 500px;
  }

//   .SI {
//     div.justify-content-between{
//         display: none!important;
//     }
//   }
}