#MODAL_RECEIVER {
  z-index: 9999;
  width: 335px;
  // height: 520px
  height: 320px;
  border: 1px solid #ccc;
  position: fixed;
  background: white;
  top: 15px;
  right: 15px;
  padding: 4px;
  border-radius: 12px;
  right: -1000px;
  opacity: 0;
  transition: all 0.5s linear;

  &.view {
    right: 15px;
    opacity: 1; }

  .closer {
    position: absolute;
    right: -10px;
    top: -10px; }



  .content {
    border-radius: 14px;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    padding: 4px 12px; }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold; }

  .content-video {
    div {
      width: 100%;
      height: 90px;
      // border-radius: 10px
 }      // background: black

    video {
      width: 300px;
      height: 200px;
      background-color: #000;
      // border: 2px solid #4caf50
      border-radius: 8px; } }

  .control {
    margin-top: 14px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;

    button {
      width: 120px; } } }
