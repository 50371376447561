.confirm-container {
    justify-content: center;
}

.style-list-none {
    list-style: none;
}

.step-wizard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-wizard-list{
    background: #fff;
    color: #333;
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    padding: 20px 10px;
    position: relative;
    max-width: 100%;
    z-index: 10;
}

.step-wizard-item{
    padding: 0 20px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 120px;
    position: relative;
}

.step-wizard-item + .step-wizard-item:after{
    content: "";
    position: absolute;
    left: 0;
    top: 19px;
    background: #3699FF;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    z-index: -10;
}

.progress-count{
    height: 40px;
    width:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index:10;
    color: transparent;
}

.progress-count:after{
    content: "";
    height: 40px;
    width: 40px;
    background: #3699FF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}

.progress-count:before{
    content: "";
    height: 10px;
    width: 20px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}

.progress-label{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before{
    display: none;
}
.current-item ~ .step-wizard-item .progress-count:after{
    height:10px;
    width:10px;
}
.current-item ~ .step-wizard-item .progress-label{
    opacity: 0.5;
}
.current-item .progress-count:after{
    background: #fff;
    border: 2px solid #3699FF;
}
.current-item .progress-count{
    color: #3699FF;
}


.step-wizard-item .ball-sync{
    color: #3699FF;
}


.ball-sync:after{
    content: "" !important;
    height: 40px !important;
    width: 40px !important;
    background: #3699FF !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 50% !important;
    z-index: -10 !important;

}

.ball-sync:before{
    content: "" !important;
    height: 10px !important;
    width: 20px !important;
    border-left: 3px solid #fff !important;
    border-bottom: 3px solid #fff !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -60%) rotate(-45deg) !important;
    transform-origin: center center !important;
    display: inline !important
}

.current-item .ball-sync:after{
    background: #fff !important;
    border: 2px solid #3699FF !important;
}

.current-item .ball-sync:before{
    display: none !important
}

.scan {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scan .fingerprint{
    position: relative;
    width: 100px;
    height: 100px;
    background-size: 100px;
}

.scan .fingerprint::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100px;
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
    0%,100%{
        height: 0%;
    }
    50%{
        height: 100%;
}
}

.scan .fingerprint::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #3699FF;
    border-radius: 4px;
    filter: drop-shadow(0 0 5px #3699FF);
    animation: animate_bar 4s ease-in-out infinite;
    }

@keyframes animate_bar {
    0%,100%{
        top: 0%;
    }
    50%{
        top: 100%;
    }
}

.scan h3{
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
    color: #3699FF;
    animation: animate_text 4s ease-in-out infinite;
}

@keyframes animate_text {
0%,100%{
    opacity: 1;
}
50%{
    opacity: 0;
}
}