.close-options{
  color: red;
  right: -13px;
  position: absolute;
  top: -12px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 22px;
  text-align: center;
  background: white;
  cursor:pointer;
  color: #ffffff;
    background-color: #F64E60;
    border-color: #F64E60;
}

.danger-button{
  color: #ffffff;
    background-color: #F64E60;
    border-color: #F64E60;
}

.lock-button{
  color: #ffffff;
  background-color: #FFA800;
  border-color: #FFA800
}

.normal-button{
  color: #FFFFFF;
  background-color: #3699FF;
  border-color: #3699FF;
}