#UploadCSVBrockPerson {
  span {
    margin-bottom: 0px;
    display: block;

    button {
      background: none;
      border: none;
      padding: 0;
      color: #3699FF;
      text-decoration: underline; } }

  a {
    padding: 0px; } }
