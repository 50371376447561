//
// Forms
//


// Form group
.form-group {

	label {
		font-size: $form-label-font-size;
		font-weight: $form-label-font-weight;
		color: $dark-75;
	}

	.invalid-feedback,
	.valid-feedback {
		font-size: $form-feedback-font-size;
		font-weight: $form-feedback-font-weight;
	}

	.form-text {
		font-size: $form-text-font-size;
		font-weight: $form-text-font-weight;
	}
}

.dropdown-flag {
	position: absolute;
	top: 20px;
	right: 20px;
}

.center-item {
	text-align: center;
}

.item-align-center {
	align-items: center;
}

.baseline-item {
	align-items: baseline;
}

.big-avatar {
	text-align: -webkit-center;
}

.slider {
	display: block;
	width: 100%;
}

.align-general-center {
	justify-content: start;
    align-items: center;
}

.left-margin {
	margin-left: 50px;
}
.weight-400 {
	font-weight: 400;
}

.disabledButton {
    pointer-events: none;
    opacity: 0.4;
}

// ion range color

.irs--flat .irs-bar {
	background-color: $primary !important;
}

.irs--flat .irs-handle>i:first-child {
	background-color: $primary !important;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
	background-color: $primary !important;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
	border-top-color: $primary !important;
}

.width-slider {
	width: 140px;
}

.PhoneInputInput {
	border: none !important;
	outline: none !important;
}

.dropdown-up {
	transition: all .5s ease-in-out;
	margin-top: .5rem;
	border-radius: 7px;
	text-align: center;
    background-color: white;
}
.width-section-modal {
    width: 50%;
}

.botao-webcam-person {
	font-size: 1.2rem;
    // vertical-align: initial;
    // line-height: 1;
    display: inline-flex;
	width: 30px;
    height: 30px;
    position: relative;
}

.botao-webcam-person i {
	position: absolute;
    left: 7.35px;
	font-size: 1.1rem !important;
}

.upload-area-btn {
	width: max-content;
	min-width: 100%;
	margin-top: 4px;
}

.width-large {
	width: 83%;
}

.button-padding {
	padding-left: 48%;
}

// Estilos icone camera
.border-success {
	border: 1px solid $secondary;
}

.color-success {
	color: $success;
}

.bgcolor-secondary {
	background-color: $secondary;
}

.border-success:hover {
	transition: all 1s ease-in-out;
	background-color: $success;
}

.border-success:hover .color-success{
	transition: all 1s ease-in-out;
	color: $white;
}

// Estilo icone file
.border-primary {
	border: 1px solid $primary;
}

.color-primary {
	color: $primary;
}

.border-primary:hover {
	transition: all 1s ease-in-out;
	background-color: $primary;
}

.border-primary:hover .color-primary{
	transition: all 1s ease-in-out;
	color: $white;
}

// Estilo icone trash
.border-danger {
	border: 1px solid $danger;
}

.color-danger {
	color: $danger;
}

.border-danger:hover {
	transition: all 1s ease-in-out;
	background-color: $danger;
}

.border-danger:hover .color-danger{
	transition: all 1s ease-in-out;
	color: $white;
}

.border-video {
	border-radius: 50%;
}

.margin-card {
	margin-top: -65px;
}

.margin-card2 {
	margin-top: -30px;
}

.align-right {
	text-align: end;
}
.space-buttons {
	justify-content: space-between;
	margin: 1rem;
}

.justify-right {
	justify-content: end;
}

.hide-file {
	display: none;
}

.section-form1 {
	width: 60%;
}

.section-form-subinfo {
	width: 40%;
}

.section-form {
	width: 30%;
}

.section-form-select-device {
	width: 30%;
	text-align: -webkit-center;
	align-self: center;
}

.input-center {
	display: flex;
	justify-content: center;
}



.section-form70 {
	width: 60%;
	padding-left: 2rem;
}

.section-form-full {
	width: 100%;
}

.section-almost-form-full {
	width: 83%;
	margin-left: 8rem;
}

.modal-center {
	align-self: center;
}

.btn-take-photo {
	border-radius: 50%;
    width: 50px;
    height: 50px;
}

.padding-none {
	padding-right: 0 !important;
}

.camera {
	position: relative;
}

.result {
	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	background-color: rgba(0,0,0,0.9);
	transition: 0.4s;

	&.hasPhoto {
		left: 0;
	}
}

.video {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.align-switch {
	text-align: center;
}

.button-device {
	display: flex;
	flex-direction: column;
    justify-content: center;
	width: 250px;
    height: 150px;
}

.button-content {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.font-size-big {
	font-size: 3rem !important;
}

.font-style-button {
	font-size: 1.3rem;
    font-weight: 500;
    margin-top: 10px;
}

.footer-button-form{
    display: flex;position: relative;
}
.align-right-button-form{
    position: absolute;
    right: 0;
}

.center-form{
    align-items: center;
    justify-content: center;
}

.center-image-avatar{
    place-content: center;
}



#tenant {
	height: auto!important;
}

// Form control
.form-control {
	height: 38.38px!important;
	// Readonly state
	&[readonly] {
		background-color: $input-readonly-bg;
	}

	// Pill style
	&.form-control-pill {
		border-radius: $input-line-height;

		&.form-control-sm {
			border-radius: $input-line-height-sm;
		}

		&.form-control-lg {
			border-radius: $input-line-height-lg;
		}
	}

	@if $form-validation-input-shadow == false {
		&:active,
		&.active,
		&:focus,
		&.focus {
			box-shadow: none !important;
		}
	}

	// Solid style
	&.form-control-solid {
		background-color: $input-solid-bg;
		border-color: $input-solid-bg;
		@include placeholder($input-solid-placeholder-color);
		color: $input-solid-color;
		transition: $transition-input;

		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $input-solid-bg-focus;
			border-color: $input-solid-bg-focus;
			color: $input-solid-color;
			transition: $transition-input;
		}
	}
}

// Hide Form Control Icon On Validation
.form-control-iconless {
	background-image: none;

	.form-control {
		background-image: none;
	}
}

// Placeholder colors
.placeholder-dark-50 {
	@include placeholder($dark-50);
}

.placeholder-dark-75 {
	@include placeholder($dark-75);
}

.placeholder-white {
	@include placeholder($white);
}

// Custom inputs
.custom-select {
	height: 38.38px !important;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file {
	width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
	box-shadow: none !important;
}

.custom-file-label {
	text-align: left;

	&:after {
	    float: left;
	}
}

// Input Group
.input-group {
	.form-control.is-valid + .input-group-append,
	.form-control.is-invalid + .input-group-append {
		margin-left: 0;
	}

	.input-group-prepend + .form-control.is-valid,
	.input-group-prepend + .form-control.is-invalid {
		margin-left: 1px;
	}

	// Sizing
	i {
		line-height: 0;
		font-size: 1.3rem;

		&:after,
		&:before {
			line-height: 0;
		}
	}

	.svg-icon {
		line-height: 0;
	}

	&.input-group-sm {
		i {
			line-height: 0;
			font-size: 1rem;
		}
	}

	&.input-group-lg {
		i {
			line-height: 0;
			font-size: 1.4rem;
		}
	}

	// Solid style
	&.input-group-solid {
		align-items: center;
		background-color: $input-solid-bg;
		@include border-radius($input-border-radius);

		&.active,
		&.focus {
			background-color: $input-solid-bg-focus;
			border-color: $input-solid-bg-focus;
			color: $input-solid-color;
			transition: $transition-input;
		}

		.form-control {
			@include input-reset();

			&.form-control-solid {
				&:active,
				&.active,
				&:focus,
				&.focus {
					background-color: $input-solid-bg-focus;
					border-color: $input-solid-bg-focus;
					color: $input-solid-color;
					transition: $transition-input;
				}
			}
		}

		&.input-group-sm {
			@include border-radius($input-border-radius-sm);
		}

		&.input-group-lg {
			@include border-radius($input-border-radius-lg);
		}

		.input-group-prepend,
		.input-group-append,
		.input-group-text {
			background-color: transparent;
			border: 0;
			padding-top: 0;
    		padding-bottom: 0;

			.btn-secondary {
				border: 0 !important;
			}
		}

		.input-group-prepend ~ .form-control {
			padding-left: 0 !important;
		}
	}
}

// Validation
.validated {
	.valid-feedback,
	.invalid-feedback {
		display: block;
	}
}

// Input icon
.input-icon {
	position: relative;

	span {
		left: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});

		i {
			line-height: 0;
			color: $dark-50;

			&:after,
			&:before {
				line-height: 0;
			}
		}

		.svg-icon {
			@include svg-icon-color($dark-50);
			@include svg-icon-size(24px);
		}
	}

	.form-control {
		padding-left: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
	}

	&.input-icon-right {
		span {
			right: 0;
			left: auto;
		}

		.form-control {
			padding-left: $input-btn-padding-x;
			padding-right: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
		}
	}
}

// Textarea reset resize
.resize-none {
	resize: none;
}

.btn-duplicated {
	border: 1px solid $primary;
    border-radius: 7px;
    background: white;
	width: 30px;
}

.arrow-down {
	color: $primary;
    font-size: 12px;
}

.div-button-duplicated{
	display: inline-grid;
}

.text-left{
	text-align: left;
}